import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../components/Layout';
import img1 from '../../images/articles/floor-plan-of-a-property.jpg';
import img2 from '../../images/articles/architect-rolls-and-architectural-plan.jpg';
import img3 from '../../images/articles/plan-with-gavel.jpg';

import '../../styles/articles-single.css';
import avatar from '../../images/user-avatar.png';

const WhatIsAPlotPlan = () => (
  <Layout
    title="What Is a Plot Plan"
    description="A clear guide to understanding plot plans: their purpose, components, applications, and creation process. Learn how plot plans support property projects."
    keywords=""
  >
    <main>
      <div className="article-page-introduction">
        <div className="article-intro-left">
          <div className="article-intro-categories">
            <Link to="/articles/category/construction-surveys-planning">
              Construction Surveys & Planning
            </Link>
          </div>

          <h1>What Is a Plot Plan</h1>
        </div>

        <div className="article-intro-right">
          <div className="text-center my-4">
            <img src={img1} alt="Floor plan of a property" width={800} height={400} />
          </div>
        </div>
      </div>

      <p>
        If you have ever been involved in developing, buying or selling a property, you may have
        come across the term. But for the unfamiliar, just what is a plot plan, and why is it so
        important for property developments?
      </p>
      <p>
        In layman's terms, a plot plan is simply a detailed drawing that represents a property’s
        boundaries, and the design of the structures, and critical features like{' '}
        <Link to="/articles/overland-drainage-right-of-ways">easements</Link>, utility right of
        ways, and in some jurisdictions includes landscaping. A plot plan might also include
        elevations. This important document helps ensure that construction, renovations, and various
        other property management projects are planned and delivered as intended and without errors.
        What is also important is what a plot plan isn’t. The plot plan does not show the
        constructed, or “as built” location of anything, only the planned/designed location. If you
        want a document showing the real, actual, on-the-ground location you will need a Real
        Property Report (or RPR). The RPR is also the document you use when buying or selling a
        home.
      </p>
      <h2>What Is a Plot Plan Important For?</h2>
      <p>
        A plot plan provides information and transparency in many contexts such as building permit
        applications, designing the house location, landscaping and outdoor lighting and sign
        locations. In each instance and more, a plot plan essentially provides clarity by providing
        a platform to communicate to all the interested parties the plan for the area.
      </p>
      <p>
        <strong>
          Plot plans ensure compliance with strict zoning and by-laws, help avoid costly mistakes,
          and facilitate communication between property owners, contractors, and governing
          authorities clearer and transparent.
        </strong>
      </p>
      <h2>What Is a Plot Plan Composed Of?</h2>
      <p>
        A plot plan is a detailed drawing (or map) that outlines the dimensions and layout of a
        property, revealing essential features like boundaries, structures, and utilities. You can
        think of it as a high level view of the intended construction, design, and zoning
        compliance. If we drill down a little deeper, we can better grasp what a plot plan is
        through its components, with each one contributing to an accurate representation of the
        property:
      </p>
      <ul>
        <li>
          <strong>Property Boundaries</strong>
          <br />
          The plot plan clearly defines the exact limits of the property based on legal
          descriptions. Sometimes these will be derived from the subdivision plan, and sometimes
          they will be re surveyed at the start of a new big project in anticipation of significant
          construction efforts. These boundaries help avoid designs that create frustrating disputes
          over shared or neighbouring land, encroachments into right of ways or violations of
          bylaws.
        </li>

        <li>
          <strong>Structures</strong>
          <br />
          Existing and proposed structures, such as houses, garages, and sheds, are all located
          definitively on the plan. This offers a visual guide for future construction or
          modifications. Paved driveways might also be shown.
        </li>

        <li>
          <strong>Easements and right of ways</strong>
          <br />
          Easements and right of ways are rights granted to third parties, such as utility
          companies, to access specific parts of the property. There may also be areas where no
          development is allowed, or have restrictions on the height of building or type of
          buildings. These areas are prominently displayed to ensure proper planning.
        </li>

        <li>
          <strong>Utilities and Drainage Systems</strong>
          <br />
          Utility structures,{' '}
          <Link to="/articles/overland-drainage-right-of-ways">drainage paths</Link>, and electrical
          connections are usually included, ensuring compliance with infrastructure and
          environmental requirements. Gas and water connections as well as power poles are sometimes
          included.
        </li>
        <li>
          <strong>Landscaping Details</strong>
          <br />
          Features like trees, gardens, and driveways are noted, providing a comprehensive overview
          of the property’s layout.
        </li>
      </ul>
      <h2>Quick Reference Table: Plot Plan Components</h2>
      <table className="table-chart">
        <thead>
          <tr>
            <th>Component</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Property Boundaries</td>
            <td>Defines legal limits of the property</td>
          </tr>
          <tr>
            <td>Structures</td>
            <td>Marks existing and planned buildings</td>
          </tr>
          <tr>
            <td>Easements & right of ways</td>
            <td>Identifies rights attributed to third parties</td>
          </tr>
          <tr>
            <td>Utilities and Drainage</td>
            <td>Maps utility right of ways and water drainage systems</td>
          </tr>
          <tr>
            <td>Landscaping Details</td>
            <td>Highlights gardens, trees, and outdoor features</td>
          </tr>
        </tbody>
      </table>
      <h2>Purpose and Applications of Plot Plans</h2>
      <p>
        Again, a plot plan is an important piece of documentation in various property-related
        scenarios. It affords precise, clearly defined information that ensures projects are planned
        and executed effectively.
      </p>
      <p>
        By clearly illustrating property boundaries, existing structures, and proposed developments,
        plot plans are a key aspect of maintaining clarity and compliance with legal and zoning
        regulations.
      </p>
      <h3>Applications in Construction and Renovations</h3>
      <p>
        Plot plans become part of the process when entering into a new construction or renovation
        project, helping contractors and architects understand and plan the property layout and
        ensuring that new structures fit within the allowable boundaries and adhere to local
        building codes. You might be positioning a new garage, for example, or expanding an existing
        home - either way, a well-prepared plot plan eliminates guesswork and hopefully prevents
        costly errors.
      </p>
      <h3>Permit Applications</h3>
      <p>
        Obtaining permits for construction or land modifications often requires a detailed plot
        plan. Municipal authorities rely on this document to verify that the proposed work aligns
        with zoning laws and does not encroach on restricted areas, such as utility easements or
        setback requirements. A plot plan is, therefore, a prerequisite for smooth and timely permit
        approvals.
      </p>
      <h3>Real Estate Transactions</h3>
      <p>
        Plot plans are also indispensable for buyers during property sales, as they can help them
        comprehend the full scope of the property, including boundaries, easements, and existing
        structures. Sellers, on the other hand, can use plot plans to resolve potential disputes
        before they arise, providing greater transparency during the transaction.
      </p>
      <h3>Resolving Property Line Disputes</h3>
      <p>
        <Link to="/articles/relaxation-and-encroachment-info-2022">Boundary disputes</Link> between
        neighbours can be contentious, expensive, and deeply frustrating. We do not suggest that
        plot plans be used to resolve property line disputes - this is better done through a
        property line stakeout to show the actual property line on the ground marked with nails or
        stakes.
      </p>
      <h3>Compliance with Zoning Laws</h3>
      <p>
        Lastly, plot plans are important in ensuring compliance with zoning regulations.
        Municipalities use them to confirm that developments meet setback requirements, respect
        easements, and conform to the property's intended use, such as residential or commercial.
      </p>
      <p>
        Essentially, plot plans simplify planning, reduce risks, and encourage more fact-based
        decision-making in various contexts. Having a clear and accurate plot plan is almost
        indispensable regardless of the size of the project—from a small urban home to a large-scale
        industrial development.
      </p>
      <div className="text-center my-4">
        <img
          src={img2}
          alt="architect rolls and architectural plan, technical project drawing"
          width={800}
          height={400}
        />
      </div>
      <h2>How a Plot Plan Is Created</h2>
      <p>
        Creating a plot plan involves integrating data form many sources, legal boundaries of the
        parcel, righ of ways, field data that shows the location of existing structures and
        features, as well as the designed location of new items to produce an accurate
        representation of a property. The process demands attention to detail and adherence to local
        standards, whether for construction, renovations, or legal purposes.
      </p>
      <p>Let's take a quick look at some of the steps used to create a plot plan:</p>
      <h3>Step 1: Property Survey</h3>
      <p>
        The creation of a plot plan usually begins with a detailed survey of the property.
        Professional Alberta Land Surveyors visit the site to measure and map the land. Using
        specialized tools such as total stations, and GNSS (GPS) equipment they collect accurate
        data about property boundaries, existing structures, and natural features. This step is
        essential for ensuring the final plan reflects the real-world layout.
      </p>
      <h3>Step 2: Data Analysis and Mapping</h3>
      <p>
        Once the field data is collected, surveyors transfer the information into a digital product.
        Computer-aided design (CAD) software is commonly used to generate a detailed plot plan. This
        software allows for integration and editing of survey data, and design of new structures,
        clear annotations, and the ability to adjust plans as needed. Incorporating data on
        easements, right of ways, <Link to="/articles/utility-right-of-ways">utilities</Link>, and
        drainage systems ensures the plan is both comprehensive and functional.
      </p>
      <h3>Step 3: Collaboration with Experts</h3>
      <p>
        Collaboration is key to creating a plot plan that meets legal and practical requirements.
        Surveyors' work is generally passed over to architects, engineers, and urban planners to
        finalize the design. These professionals ensure the plan aligns with zoning laws,
        construction codes, and the project’s objectives.
      </p>
      <h3>Step 4: Final Review and Compliance</h3>
      <p>
        The plot plan undergoes a thorough review before submission or use. Surveyors and other
        professionals check for accuracy and confirm compliance with local regulations but the
        municipality has the final say on the design.
      </p>
      <h2>Why Accuracy Is Important</h2>
      <p>
        It's easy to think of a plot plan as a technical drawing and nothing more. However, they are
        of great importance in many ways. The plot plan is the base drawing that supports all the
        other construction activities on the parcel of land and used for critical decision-making.
        Ensuring its accuracy protects property owners, contractors, and developers from potential
        disputes or expensive mistakes.
      </p>
      <p>
        Hiring licensed <Link to="/about-us">legal surveyors</Link> for this process is not only
        recommended but absolutely essential to meeting industry standards and maintaining legal
        integrity. Within Alberta, an Alberta Land Surveyor is required as soon as any land
        boundaries are involved.
      </p>
      <div className="text-center my-4">
        <img src={img3} alt="a gravel lying on some construction plans" width={350} height={250} />
      </div>
      <h2>Bottom Line</h2>
      <p>
        Plot plans are essential in property-related projects. They provide a detailed and accurate
        view of a property, helping homeowners, contractors, and real estate professionals work
        toward clarity.
      </p>
      <p>
        When accuracy and compliance are required - as they always are with property sales -
        consulting <Link to="/articles/land-surveyors-and-rprs/">full-service legal surveys</Link>{' '}
        and geomatics professionals to create a Real property report (RPR) is the direction to go.
      </p>
      <p>
        <strong>
          If you need a plot plan or require other services,{' '}
          <a href="mailto:info@axiomgeomatics.ca">reach out to our trusted local surveyors</a> to
          ensure your plot plan meets all legal and technical requirements.
        </strong>
      </p>
    </main>
  </Layout>
);

export default WhatIsAPlotPlan;
